<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Plot {{ plot.id }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'plan-list'}">Plots</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ plot.plot }}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs class="tab-solid tab-solid-primary">
            <b-tab active>
              <template slot="title">
                <i class='mdi mdi-buffer'></i> Summary
              </template>
              <form class="forms-sample">
                <div class="row">
                  <div class="col-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <b-form-group label="Plan ID"  label-for="plan_id">
                          <b-form-input type="text" disabled id="plan_id" v-model="plot.plan_id" placeholder="Plan ID"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Points"  label-for="points">
                          <b-form-input type="text" disabled id="points" v-model="plot.points" placeholder="Points"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="no_of_favourite" horizontal label="No of Favourite">
                          <b-form-input type="text" disabled id="no_of_favourite" v-model="plot.no_of_favourite"  placeholder="No of Favourite"></b-form-input>
                        </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <b-form-group label="Meter Sq"  label-for="meter_sq">
                          <b-form-input type="text" disabled id="meter_sq" v-model="plot.meter_sq" placeholder="Meter Square"></b-form-input>
                        </b-form-group>
                        <b-form-group label="No of Feedback"  label-for="no_of_feedback">
                          <b-form-input type="text" disabled id="no_of_feedback" v-model="plot.no_of_feedback" placeholder="No of Feedback"></b-form-input>
                        </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <b-form-group class="float-right">
                        <b-button variant="success" :to="{name: 'plan-edit', params: {id: plot.id}}" class="mr-2">Edit</b-button>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              </form>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>

//import StarRating from 'vue-star-rating'
import helper from '../../util/helper.js'

export default {
  name: 'profile',
  data() {
    return {
      plot: {}
    }
  },
  computed: {

  },
  components: {
    //StarRating
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    }
  },
  created() {
    this.$store.dispatch("plot/fetchPlot", this.$route.params.id)
    .then(res => {
       this.plot = res.data.data
    })
  }
}
</script>
